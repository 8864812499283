<template>
  <main class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="page__head__actions">
      <div class="d-flex w-100 mt-3 justify-content-end">
        <div class="align-self-center mx-2 dropdown">
          <button
            class="button button--grey text--capital text--small d-flex align-items-center"
            type="button"
            @click="showDateFilterModal"
          >
            <span class="filter_text"> Filter by date range</span>
          </button>
        </div>
        <div class="align-self-center mx-2 dropdown">
          <button
            class="button button--grey text--capital text--small d-flex align-items-center"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            export
            <span class="dropdown__icon">
              <ion-icon name="caret-down-outline"></ion-icon>
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <download-excel
              class="dropdown-item text--capital"
              style="cursor: pointer"
              :data="download"
              :fields="json_fields"
              v-if="userCanExportToPDF"
              type="csv"
              name="sales_summary_report.csv"
            >
              CSV
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <hr style="border: 1px solid #e5e5e5" />

    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 80%"
            >
              parameters name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              parameters values
            </th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="salesSummaryData.length && !isLoading">
          <tr v-for="queue in salesSummaryData" v-bind:key="queue.paramerterId">
            <td>
              <span
                @click="viewSalesDetailsBreakDOwn(queue.paramerterId)"
                class="table__body__text table__text text--capital text--primary cursor"
              >
                {{ queue.parameterName }}</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">
                {{ addCommas(Number(queue.parameterValues)) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <DateFilter
      :show="showFilter"
      @close="showDateFilterModal"
      @statementAlert="imageAlert"
      @clear="clearDateFilterModal"
      @getFilterResult="getFilterResult"
    />

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import ApiService from "@/core/services/general.service";
import JsonExcel from "vue-json-excel";
import DateFilter from "./DateFilter.vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";

export default Vue.extend({
  name: "sale_summary_report",
  data: function () {
    return {
      content_header: {
        title: "Sales Summary Report",
        subTitle: "Show your team sales performances.",
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      size: 20,
      pageIndex: 1,
      salesSummaryData: [],
      isLoading: false,
      json_fields: {
        "Loan Summary Parameters Name": "parameterName",
        "Loan Summary Parameters Value": "parameterValues",
      },
      startDate: null,
      endDate: null,
      showFilter: false,
    };
  },
  components: {
    downloadExcel: JsonExcel,
    DateFilter,
  },
  methods: {
    async getSalesSummaryReport() {
      try {
        this.isLoading = true;
        const data = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
        const res = await ApiService.post(`SalesReport/get-sales-report`, data);
        this.salesSummaryData = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data;
        this.alertError(error);
      }
    },

    getFilterResult({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;

      this.getSalesSummaryReport();
      this.showDateFilterModal();
    },

    showDateFilterModal() {
      this.showFilter = !this.showFilter;
    },

    clearDateFilterModal() {
      this.showFilter = false;
      this.startDate = null;
      this.endDate = null;
      this.getSalesSummaryReport();
    },

    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }
      if (type === "success") {
        this.alertSuccess(message);
      }
      if (type === "warning") {
        this.alertWarning(message);
      }
    },

    addCommas(num) {
      const number = (num / 1).toFixed(0).replace(",", ".");
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    viewSalesDetailsBreakDOwn(key) {
      // this.$router.push({ path: `/sales_report_details/${key}` });
      this.$router.push({
        name: "sales_report_details",
        params: { id: key, startDate: this.startDate, endDate: this.endDate },
      });
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description, array = null) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = array;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      this.alert.array = null;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    // Paging
    changePage(pageNum) {
      this.pageIndex = pageNum;
    },

    stepPage(direction) {
      if (direction == "next") {
        this.pageIndex = this.paging.currentPage + 1;
      }
      if (direction == "previous") {
        this.pageIndex = this.paging.currentPage - 1;
      }
    },

    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
  },

  computed: {
    download() {
      let data = [];
      if (this.salesSummaryData.length) {
        this.salesSummaryData.map((item) => {
          let new_obj = { ...item };

          data.push(new_obj);
        });
      }

      return data;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
  },

  async created() {
    this.getSalesSummaryReport();
  },

  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
});
</script>
<style scoped>
.customtable tbody td {
  font-weight: inherit;
  font-size: 1.4rem;
  line-height: 17px;
  color: #1e1f20;
}
.has-checkbox,
.text--lh2 {
  line-height: 2rem;
}
.has-checkbox .checkmark {
  top: 0px;
}
label.banner .checkmark {
  top: 0;
}
.form__item-bulk {
  margin-bottom: 2rem;
}
.cursor {
  cursor: pointer;
}
</style>
